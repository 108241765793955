<template>
  <NonGPAGradingFilter
    :csv-filter-options="displayedFilterOptions"
    :already-selected-displayed-columns="filtersData.displayedColumns"
    @apply="applyFilters"
  />
  <div v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></div>
  <div v-else-if="!isLoading && isEmpty(gradingStuctureList)">
    <NoRecordFound />
  </div>
  <TableWrapper v-else>
    <THead>
      <TRHead>
        <TH
          v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
          v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
          :key="head.id"
        >
          {{ head.dataTableName }}
        </TH>
      </TRHead>
    </THead>
    <TBody>
      <TRBody v-for="(structure, index) in gradingStuctureList" :key="index">
        <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
          <span
            class="text-primary-purple-600 cursor-pointer"
            @click="redirectToDetailPage(structure.id)"
          >
            {{ structure.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </span>
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Description', ['Description'])">
          {{ structure.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])">
          <TableAction
            :idx="index"
            :current-user="structure"
            :action-list="actionList"
            @action="typeAction"
          />
        </TD>
      </TRBody>
    </TBody>
  </TableWrapper>
  <Pagination
    v-if="gradingStuctureCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
    v-show="!isLoading"
    :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
    :max-range="paginationCounts(gradingStuctureCounts, GENERAL_CONSTANTS.RECORD_LIMIT)"
    @filterRecord="filtersRecords"
  />
  <!-- Add and edit exam type -->
  <NoNGPAStructureModal
    v-if="isModalShow"
    :modal="isModalShow"
    :edit="currentSelectedStructure"
    @toggle="toggleStructureModal"
  />
  <UIConfirmationModal
    v-if="deleteModelShow"
    :modal="deleteModelShow"
    heading="DELETE_FEE_TYPE"
    message="Are you sure you want to delete "
    button-text="Delete"
    :name="currentSelectedStructure.title"
    remaining-message="grading structure ?"
    @cancel="toogleDeleteModel"
    @confirm="deleteStructureById"
  />
</template>

<script>
import NonGPAGradingFilter from '@src/router/views/exam-planner/non-gpa-grading/NonGPAGradingStructureFilter.vue'
import { mapActions, mapState } from 'vuex'
import TableAction from '@components/TableAction.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import Pagination from '@components/BaseComponent/Pagination.vue'
import isEmpty from 'lodash/isEmpty'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import NoNGPAStructureModal from '@src/router/views/admin/modals/AddAndEditNonGpaStructureModal.vue'
import { paginationCounts } from '@src/components/BaseComponent/pagination.util.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import gpaStuctureData from '@src/router/views/exam-planner/gpa-grading/gpaStuctureData.json'
import fileMixin from '@src/mixins/file-mixins'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'ExamTypes',
  components: {
    NonGPAGradingFilter,
    TableAction,
    Loader,
    Pagination,
    UIConfirmationModal,
    NoRecordFound,
    NoNGPAStructureModal,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [fileMixin, scrollMixin],
  data() {
    return {
      filtersData: {},
      GENERAL_CONSTANTS,
      dashboard: 'dashboard',
      deleteModelShow: false,
      defaultSelectedColumns: gpaStuctureData.defaultSelectedColumns,
      tableHeaders: gpaStuctureData.tableHeaders,
      skipForFilterColumns: gpaStuctureData.defaultSelectedColumns,
      gradingStuctureList: [],
      modalButtonLoading: false,
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      gradingStuctureCounts: 0,
      currentSelectedStructure: null,
      isModalShow: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapState('layout', [
      'currentCampusScope',
      'currentSectionScope',
      'currentClassScope',
      'activeRole',
    ]),
    ...mapState({
      showModal: (state) => state.layout.showModal,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.filtersRecords()
      },
      deep: true,
    },
    currentSectionScope: {
      handler() {
        this.filtersRecords()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.filtersRecords()
      },
      deep: true,
    },
    activeRole: {
      handler() {
        this.filtersRecords()
      },
      deep: true,
    },
  },
  mounted() {
    this.setRightbarData()
    this.filtersRecords()
  },
  methods: {
    showColumnInDataTable,
    isEmpty,
    paginationCounts,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filtersRecords()
    },
    async filtersRecords(range) {
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      const [res, err] = await this.getNonGPAStructureList(payload)
      if (!err) {
        this.gradingStuctureList = res?.data?.records
        this.gradingStuctureCounts = res?.data?.meta?.total_records
        this.setRightbarData(res.data?.meta?.total_records)
      }
      this.isLoading = false
    },
    toggleStructureModal(payload) {
      this.isModalShow = !this.isModalShow
      if (!this.isModalShow) this.currentSelectedStructure = null
      if (payload) this.filtersRecords()
    },
    typeAction(action, currentType, idx) {
      this.currentSelectedStructure = currentType
      switch (action) {
        case 'Edit':
          this.toggleStructureModal()
          break
        case 'Delete':
          this.toogleDeleteModel()
          break
      }
    },
    deleteStructureById() {
      this.deleteStructure(this.currentSelectedStructure.id)
    },
    async deleteStructure(id) {
      await this.deleteNonGPAGradingStructure(id)
      this.toogleDeleteModel()
      this.filtersRecords()
    },
    toogleDeleteModel() {
      this.deleteModelShow = !this.deleteModelShow
      if (!this.deleteModelShow) this.currentSelectedStructure = null
    },
    redirectToDetailPage(id) {
      this.$router?.push({ name: 'non-gpa-grading-structure-detail-view', params: { id: id } })
    },
    setRightbarData(count = 0) {
      const rightBarContent = {
        header: {
          title: 'NON_GPA_G_S',
          buttons: [
            {
              title: 'ADD_NON_GPA_G_S',
              classes: ['primary-button-right'],
              action: {
                func: () => {
                  this.toggleStructureModal()
                },
              },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [{ text: 'AVAIL_STRUCT', value: count }],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('exams', ['getNonGPAStructureList', 'deleteNonGPAGradingStructure']),
  },
}
</script>
